<template>
  <div class="box_box">
    <div class="box">
      <div class="item item1" @click="handle_more('网上开户')">
        <img
          src="@/assets/img/icon.png"
          style="height: 96px; width: 96px; margin-top: 49px"
          alt=""
        />
        <div class="title">网上开户</div>
        <div class="title_">Open an account online</div>
        <div class="hover_box hover_box1">
          <div class="hover_top">
            <div class="hover_title">网上开户</div>
            <div class="hover_title_">Open an account online</div>
          </div>
          <img
            src="@/assets/img/icon.png"
            style="height: 96px; width: 96px; margin-top: 35px"
            @click="handle_more('网上开户')"
          />
          <div class="enter_" @click="handle_more('网上开户')">点击查看</div>
        </div>
      </div>
      <div class="item item2" @click="handle_more('软件下载')">
        <img
          src="@/assets/img/icon(1).png"
          style="height: 96px; width: 96px; margin-top: 49px"
          alt=""
        />
        <div class="title">软件下载</div>
        <div class="title_">Software download</div>
        <div class="hover_box hover_box2">
          <div class="hover_top">
            <div class="hover_title">软件下载</div>
            <div class="hover_title_">Software download</div>
          </div>
          <div class="hover_text_box">
            <div
              v-for="item in softwareList"
              :key="item.id"
              style="position: relative"
              @click="handle_more('软件下载')"
            >
              <span class="hover_triangle"></span>
              <span class="hover_text">{{ item.name }}</span>
            </div>
          </div>
          <div class="hover_more" @click="handle_more('软件下载')">
            查看更多>>
          </div>
        </div>
      </div>
      <div class="item item3" @click="handle_more('研究资讯')">
        <img
          src="@/assets/img/icon(2).png"
          style="height: 96px; width: 96px; margin-top: 49px"
          alt=""
        />
        <div class="title">研究资讯</div>
        <div class="title_">Research information</div>
        <div class="hover_box hover_box3">
          <div class="hover_top">
            <div class="hover_title">研究资讯</div>
            <div class="hover_title_">Research information</div>
          </div>
          <img
            src="@/assets/img/icon(2).png"
            style="height: 96px; width: 96px; margin-top: 35px"
            @click="handle_more('研究资讯')"
          />
          <div class="enter_" @click="handle_more('研究资讯')">点击查看</div>
        </div>
      </div>
      <div class="item item4" @click="handle_more('培训专栏')">
        <img
          src="@/assets/img/z6.png"
          style="height: 96px; width: 96px; margin-top: 49px"
          alt=""
        />
        <div class="title">培训专栏</div>
        <div class="title_">Training column</div>
        <div class="hover_box hover_box4">
          <div class="hover_top">
            <div class="hover_title">培训专栏</div>
            <div class="hover_title_">Training column</div>
          </div>
          <img
            src="@/assets/img/z6.png"
            style="height: 96px; width: 96px; margin-top: 35px"
            @click="handle_more('培训专栏')"
          />
          <div class="enter_" @click="handle_more('培训专栏')">点击查看</div>
        </div>
      </div>
      <div class="item item5" @click="handle_more('营业网点')">
        <img
          src="@/assets/img/z5.png"
          style="height: 96px; width: 96px; margin-top: 49px"
          alt=""
        />
        <div class="title">营业网点</div>
        <div class="title_">Business outlets</div>
        <div class="hover_box hover_box5">
          <div class="hover_top">
            <div class="hover_title">营业网点</div>
            <div class="hover_title_">Business outlets</div>
          </div>
          <div class="hover_text_box">
            <div
              v-for="item in sales_network"
              :key="item.id"
              style="position: relative"
              @click.stop="handle_more('营业网点', item)"
            >
              <span class="hover_triangle"></span>
              <span class="hover_text">{{ item.name }}</span>
            </div>
          </div>
          <div class="hover_more" @click="handle_more('营业网点')">
            查看更多>>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sales_network: [],
      softwareList: [],
      research_consultingList: [],
      account_list: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      // 软件列表
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/software/list`,
      }).then((res) => {
        if (res.data.length >= 4) {
          this.softwareList = [res.data[0], res.data[1], res.data[2], res.data[3]];
        } else {
          this.softwareList = res.data;
        }
      });
      //  研究咨询
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/cms/list_by_column_name?columnName=交易指南&start=0&limit=4`,
      }).then((res) => {
        this.research_consultingList = res.data.rows;
      });
      // //  网上开户
      // this.$axios({
      //   method: "get",
      //   headers: {
      //     'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
      //   },
      //   url: `/api/web/cms/list_by_column_name?columnName=网上开户&start=0&limit=4`,
      // }).then((res) => {
      //   this.account_list = res.data.rows;
      // });
      // 营业网点  sales network
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/navigation/list_by_parent_id?parentId=1458834147115040&start=0&limit=4`,
      }).then((res) => {
        this.sales_network = res.data;
      });
    },
    handle_more(data, ele) {
      if (data === '软件下载') {
        this.$router.push({
          path: "/app_download",
        });
      } else if (data === '研究资讯') {
        this.$router.push({
          path: "/second_level/study_information",
        });
      } else if (data === '网上开户') {
        this.$router.push({
          path: "/online_open_account",
        });
      } else if (data === '营业网点') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: ele ? ele.columnId : '1460440529895456',
            id: ele ? ele.id : '1458834147115040',
          },
        });
      } else if (data === '培训专栏') {
        window.open('http://edu.hyfutures.com')
      }
    },
  },
};
</script>
<style scoped>
.box_box {
  width: 100%;
  height: 332px;
  background-image: url("../../../assets/img/a12.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center; /*定义body的元素垂直居中*/
}
.box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}
.item {
  width: 222px;
  height: 264px;
  background: rgba(255, 255, 255, 0.92);
  border-radius: 0px 0px 8px 8px;
  text-align: center;
  border-top: 2px #c21d14 solid;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.title {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #222222;
  line-height: 38px;
}
.title_ {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #222222;
  line-height: 28px;
  opacity: 0.8;
}
.enter_ {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c21d14;
  line-height: 28px;
}
.hover_title {
  margin-top: 10px;
  height: 22px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.hover_title_ {
  height: 16px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  opacity: 0.8;
}
.hover_top {
  height: 60px;
  background: rgb(194, 29, 20);
  overflow: hidden;
  width: 100%;
}
.hover_box {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  top: -268px;
  transform: rotateX(270deg);
  transition: all 0.5s ease-in-out;
}
.item1:hover .hover_box1 {
  transform: translate(0px, 268px);
}
.item2:hover .hover_box2 {
  transform: translate(0px, 268px);
}
.item3:hover .hover_box3 {
  transform: translate(0px, 268px);
}
.item4:hover .hover_box4 {
  transform: translate(0px, 268px);
}
.item5:hover .hover_box5 {
  transform: translate(0px, 268px);
}
.hover_triangle {
  display: inline-block;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  border-left: 5px solid #dbdbdb;
  border-right: 5px solid rgba(255, 255, 255, 0);
  position: relative;
  top: -9px;
}
.hover_text_box {
  text-align: left;
  padding: 22px 22px 13px 22px;
}
.hover_text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #222222;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 165px;
  display: inline-block;
}
.hover_more {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c21d14;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 22px;
  width: 100%;
}
</style>
