<template>
  <div class="swiper-container" id="swiper1">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in carousel_list" :key="item.id1">
        <img
          v-if="item.cover"
          style="width: 100%; height: 100%"
          :src="http + item.cover + '?imageMogr2/thumbnail/!70p'"
          @click="handle_(item)"
        />
        <video
          v-if="item.video"
          autoplay
          loop
          muted
          width="100%"
          :src="vod + item.video"
          alt=""
        />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination my_pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>

    <!-- 如果需要滚动条 -->
    <!--    <div class="swiper-scrollbar"></div>-->
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      carousel_list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      var mySwiper = new Swiper("#swiper1", {
        initialSlide: 0,
        loop: true,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        //如果需要自动切换海报
        autoplay: 10000,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          mySwiper.startAutoplay();
          mySwiper.reLoop();
        },
      });
    });
    this.getlist();
  },
  methods: {
    handle_(data) {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/cms/view?id=${data.id}`,
      });
      if (data.link.substring(0, 4) == 'http') {
        window.open(data.link)
      } else {
        this.$router.push({
          path: data.link,
        });
      }
    },
    getlist() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/cms/list_by_column_name?columnName=网站轮播图&start=0&limit=5`,
      }).then((response) => {
        this.carousel_list = response.data.rows;
      });
    },
  },
};
</script>
<style>
#swiper1 .my_pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  display: inline-block !important;
  border-radius: 100% !important;
  background: #fff !important;
  opacity: 1 !important;
}
#swiper1 .my_pagination .swiper-pagination-bullet-active {
  width: 30px !important;
  height: 10px !important;
  background: #ffffff !important;
  border-radius: 5px !important;
}
.swiper-container {
  /* height: 625px; */
  width: 100%;
}
.swiper-wrapper {
  /* height: 625px; */
  width: 100%;
}
#swiper1 .swiper-slide {
  width: 100%;
  /* height: 625px; */
  overflow: hidden;
  text-align: center;
}
#swiper1 .my_swiper-slide {
  line-height: 68px;
}
#swiper1 .swiper-button-prev {
  background-image: url("../../../assets/img/j2.png") !important;
  background-size: 60px;
  width: 60px;
  height: 60px;
  margin-left: 45px;
  cursor: pointer;
}
#swiper1 .swiper-button-next {
  background-image: url("../../../assets/img/j1.png") !important;
  background-size: 60px;
  width: 60px;
  height: 60px;
  margin-right: 45px;
  cursor: pointer;
}
</style>
