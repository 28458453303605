<template>
  <div>
    <div style="width: 100%; height: 1px; background: #e7e7e7"></div>
    <div class="company">
      <div class="company_left">
        <div class="Notice_box">
          <span class="Notice_title">交易公告</span>
          <span class="Notice_more" @click="handle_skip">查看更多></span>
        </div>
        <div class="Notice_image">
          <img src="@/assets/img/j5.png" style="width: 100%" />
        </div>
        <div class="Notice_list">
          <div class="Notice_list_item" :style="{
            'border-bottom':
              Notice_list.length == index - 0 + 1
                ? 'none'
                : '1px #E7E7E7 solid',
          }" v-for="(item, index) in Notice_list" :key="item.id">
            <span class="Notice_list_yuan"></span>
            <span class="Notice_list_content" @click="handle_article_details(item, item.id, item.columnId)">{{
                item.title
            }}
            </span>
            <span class="Notice_list_time">{{ item.publishTime }}</span>
          </div>
        </div>
      </div>
      <div class="company_right">
        <div class="Notice_box">
          <span class="Notice_title">交易日历</span>
        </div>
        <div class="box_calendar">
          <i v-show="fan" class="el-icon-refresh-right my_icon" @click="handle_f"></i>
          <div class="box_calendar_front">
            <el-row>
              <el-col :span="20">
                <el-calendar id="calendar" v-model="value">
                  <template slot="dateCell" slot-scope="{ date, data }">
                    <!--自定义内容-->
                    <div v-if="false">{{ date }}</div>
                    <div class="calendar-day" @click="
                      handle_article(
                        dianDate.indexOf(data.day) != -1,
                        data.day
                      )
                    " :style="
  moment().format('yyy-MM-DD') === data.day
    ? 'text-align: center;height: 35px;border: 2px solid #C21D14;border-radius: 4px;width: 35px;margin: 0 auto;'
    : 'text-align: center;'
">
                      {{ data.day.split("-")[2] }}
                      <span class="dian" v-if="dianDate.indexOf(data.day) != -1"></span>
                      <span class="heng" v-if="brightDate.indexOf(data.day) != -1"></span>
                      <span class="heng1" v-if="prompt_day.indexOf(data.day) != -1"></span>
                    </div>
                  </template>
                </el-calendar>
              </el-col>
              <el-col :span="4" class="calendar_annotation">
                <div class="annotation_text">注释：</div>
                <div class="jiaoyi">
                  <div style="
                      width: 20px;
                      height: 4px;
                      background: #bcbcbc;
                      border-radius: 2px;
                      margin: 0 auto;
                      margin-bottom: 10px;
                    "></div>
                  <div>交易日</div>
                </div>
                <div class="jiaoyi">
                  <div style="
                      width: 20px;
                      height: 4px;
                      background: #c21d14;
                      border-radius: 2px;
                      margin: 0 auto;
                      margin-bottom: 10px;
                      opacity: 0.5;
                    "></div>
                  <div>最后交易日</div>
                </div>
                <div class="weidu">
                  <div style="
                      width: 7px;
                      height: 7px;
                      background: #c21d14;
                      border-radius: 4px;
                      margin: 0 auto;
                      margin-bottom: 7px;
                    "></div>
                  <div class="weidu1" style="padding: 0 26px">交易公告</div>
                </div>
                <div class="dangqian">
                  <div style="
                      width: 17px;
                      height: 17px;
                      background: #fff;
                      border: 2px solid #c21d14;
                      border-radius: 4px;
                      margin: 0 auto;
                      margin-bottom: 8px;
                    "></div>
                  <div class="dangqian1" style="padding: 0 26px">当前日期</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="box_calendar_reverse">
            <div class="Notice_list1">
              <!--交易日提示-->
              <div class="Notice_list_item1" v-for="item in article_list2" :key="item.id">
                <!-- {{ item.content }} -->
                <span class="Notice_list_time1">{{ item.day }}</span>
                <span class="Notice_list_content1" v-html="item.content">
                </span>
              </div>
              <div class="my-line" v-if="article_list2.length > 0 && article_list.length > 0"></div>
              <div class="Notice_list_item" :style="{
                'border-bottom':
                  article_list.length == index - 0 + 1
                    ? 'none'
                    : '1px #E7E7E7 solid',
              }" v-for="(item, index) in article_list" :key="item.id">
                <span class="Notice_list_yuan"></span>
                <span v-if="item.targetObject" class="Notice_list_content" @click="
                  handle_article_details(
                    item,
                    item.targetObject.id,
                    item.targetObject.columnId
                  )
                ">{{ item.targetObject.title }}
                </span>
                <span v-if="item.targetObject" class="Notice_list_time">{{
                    item.targetObject.lastModified
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      fan: false,
      dataList: [],
      moment,
      Notice_list: [],
      calendarData: [],
      value: new Date(),
      my_value: "",
      article_list: [],
      article_list2: [],
      prompt_day: [],
    };
  },
  computed: {
    brightDate() {
      let ary = [];
      for (let i in this.calendarData) {
        if (this.calendarData[i].isTrading) {
          ary.push(this.calendarData[i].day);
        }
      }
      return ary;
    },
    // promptDate () {
    //   let ary = [];
    //   for (let i in this.prompt_day) {
    //     if (this.prompt_day[i].isTrading) {
    //       ary.push(this.prompt_day[i].day);
    //     }
    //   }
    //   return ary;
    // },
    dianDate() {
      let ary = [];
      for (let i in this.calendarData) {
        if (this.calendarData[i].objects.length) {
          ary.push(this.calendarData[i].day);
        }
      }
      return ary;
    },
  },
  mounted() {
    this.getDataList();
    this.handle_prompt_day();
    this.getList(moment(this.value).format("yyyy-MM-DD"));
    this.on();
  },
  methods: {
    handle_prompt_day() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/query_contracts`,
      }).then(res => {
        this.prompt_day = res.data.data.map(item => item.maturityDate)
      })
    },
    handle_skip() {
      this.$router.push({
        path: "/article_list",
        query: {
          //name: "交易公告",
          column_id: process.env.VUE_APP_buy_tips_p,
          id: process.env.VUE_APP_buy_tips
        },
      });
    },
    getDataList() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        //url: `/api/web/cms/list_by_column_name?columnName=交易公告&start=0&limit=6`,
        url: `/api/web/cms/list_by_column_name_nested?columnName=交易公告&start=0&limit=6`,
      }).then((response) => {
        let arr = [];
        response.data.rows.forEach((item) => {
          arr.push({
            ...item,
            lastModified: item.lastModified.split(" ")[0],
          });
        });
        this.Notice_list = arr;
      });
    },
    async handle_article_details(data, id, cid) {
      if (data.hasLink) {
        window.open(data.link)
      } else {
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/cms/column_by_id?id=${cid}`,
        }).then(res => {
          console.log(1, res);
          this.$router.push({
            path: "/details",
            query: {
              // name: data.cmsColumn.title,
              // id: process.env.VUE_APP_buy_tips
              my_id: id,
              name: res.data.data.title
            },
          });
        })
      }
    },
    handle_f() {
      if (
        this.$(".box_calendar_front").attr("id") == undefined ||
        this.$(".box_calendar_front").attr("id") === ""
      ) {
        this.$(".box_calendar_front").attr("id", "box_calendar_front");
      } else if (
        this.$(".box_calendar_front").attr("id") === "box_calendar_front"
      ) {
        this.$(".box_calendar_front").attr("id", "");
      }
      if (
        this.$(".box_calendar_reverse").attr("id") == undefined ||
        this.$(".box_calendar_reverse").attr("id") === "box_calendar_front"
      ) {
        this.$(".box_calendar_reverse").attr("id", "box_calendar_reverse");
      } else if (
        this.$(".box_calendar_reverse").attr("id") === "box_calendar_reverse"
      ) {
        this.$(".box_calendar_reverse").attr("id", "box_calendar_front");
      }
      this.fan = false;
    },
    handle_article(data, date) {
      if (data) {
        if (
          this.$(".box_calendar_front").attr("id") == undefined ||
          this.$(".box_calendar_front").attr("id") === ""
        ) {
          this.$(".box_calendar_front").attr("id", "box_calendar_front");
        } else if (
          this.$(".box_calendar_front").attr("id") === "box_calendar_front"
        ) {
          this.$(".box_calendar_front").attr("id", "");
        }
        if (
          this.$(".box_calendar_reverse").attr("id") == undefined ||
          this.$(".box_calendar_reverse").attr("id") === "box_calendar_front"
        ) {
          this.$(".box_calendar_reverse").attr("id", "box_calendar_reverse");
        } else if (
          this.$(".box_calendar_reverse").attr("id") === "box_calendar_reverse"
        ) {
          this.$(".box_calendar_reverse").attr("id", "box_calendar_front");
        }
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/trading_day/get_all?day=${date}`,
        }).then((response) => {
          let data = [];
          if (response.data.data.objects.length > 0) {
            let arr = response.data.data.objects;
            arr.forEach(item => {
              let newArr = item.targetClass.split('.');
              if (newArr[newArr.length - 1] == 'CmsArticle') {
                data.push(item)
              }
            })
          }
          // this.article_list = response.data.data.objects;
          this.article_list = data;
        });
        // 获取该日期交易提示列表
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/trading_prompt/list_by_day?day=${date}`,
        }).then((response) => {
          if (response.data.length > 0) {
            this.article_list2 = response.data;
          }
        });
        this.fan = true;
      }
    },
    on() {
      this.$nextTick(() => {
        // 点击上个月
        let prevBtn1 = document.querySelector(
          ".el-calendar__button-group .el-button-group>button:nth-child(1)"
        );
        prevBtn1.addEventListener("click", () => {
          // this.my_value = moment(this.value).format("yyyy-MM-DD")
          this.getList(moment(this.value).format("yyyy-MM-DD"));
        });
        // 点击今天
        let prevBtn2 = document.querySelector(
          ".el-calendar__button-group .el-button-group>button:nth-child(2)"
        );
        prevBtn2.addEventListener("click", () => {
          // this.my_value = moment(this.value).format("yyyy-MM-DD")
          this.getList(moment(this.value).format("yyyy-MM-DD"));
        });
        // 点击下个月
        let prevBtn3 = document.querySelector(
          ".el-calendar__button-group .el-button-group>button:nth-child(3)"
        );
        prevBtn3.addEventListener("click", () => {
          // this.my_value =
          this.getList(moment(this.value).format("yyyy-MM-DD"));
        });
      });
    },
    getList(data) {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/trading_day/list_by_month?year=${data.split("-")[0]
          }&month=${data.split("-")[1]}`,
      }).then((response) => {
        this.calendarData = response.data;
      });
    },
    // Tooltip 文字提示
    content(date) {
      let content = "";
      for (let i in this.calendarData) {
        if (date === this.calendarData[i].startDate) {
          content =
            this.calendarData[i].judgeName +
            ` ` +
            this.calendarData[i].tribunalName;
        }
      }
      return content;
    },
  },
};
</script>
<style>
.my_icon {
  position: absolute;
  top: -23px;
  right: 0;
  font-size: 22px;
  color: #777;
}

.Notice_list1 {
  height: 412px;
  overflow-y: auto;
}

.box_calendar {
  width: 100%;
  min-width: 483px;
  position: relative;
}

.box_calendar_reverse,
.box_calendar_front {
  width: 100%;
  min-width: 483px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  backface-visibility: hidden;
  transition: all 3s;
}

.box_calendar_reverse {
  transform: rotateY(-180deg);
}

#box_calendar_front {
  transform: rotateY(180deg);
}

#box_calendar_reverse {
  transform: rotateY(0deg);
}

.heng {
  width: 20px;
  height: 4px;
  background: #bcbcbc;
  border-radius: 2px;
  margin: 0 auto;
  margin-bottom: 10px;
  position: absolute;
  bottom: 6px;
  left: 25px;
}

.heng1 {
  width: 20px;
  height: 4px;
  background: #c21d14;
  border-radius: 2px;
  margin: 0 auto;
  margin-bottom: 10px;
  position: absolute;
  bottom: 1px;
  left: 25px;
  opacity: 0.5;
}

.dian {
  width: 7px;
  height: 7px;
  background: #c21d14;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  right: 12px;
}

.jiaoyi,
.weidu,
.dangqian,
.annotation_text {
  text-align: center;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #222222;
}

.annotation_text {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #222222;
  line-height: 20px;
}

.el-calendar {
  border: 1px solid #e7e7e7;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.el-calendar-table tr:first-child td {
  border: none !important;
}

.el-calendar-table td {
  border: none !important;
}

.calendar_annotation {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 412px;
  background: #f5f5f5;
  border-bottom: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  border-radius: 0px 10px 10px 0px;
}

.el-calendar-table thead {
  border-left: 1px solid #e7e7e7;
}

.el-calendar__header {
  background: #eaeaea;
}

.el-calendar__body {
  padding: 0 !important;
}

.el-calendar-day {
  height: 52px !important;
  position: relative;
}

.everyDay {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #409eff;
  color: #fff;
  border-radius: 50%;
}

.Notice_list_item {
  height: 51px;
  line-height: 43px;
  padding-left: 17px;
  position: relative;
  line-height: 51px;
  cursor: pointer;
}

.Notice_list_yuan {
  position: absolute;
  top: 24px;
  left: 1px;
  width: 5px;
  height: 5px;
  background: #e0e0e0;
  border-radius: 2px;
}

.Notice_list_content {
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  width: 360px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.Notice_list_content:hover {
  color: #c21d14;
}

.Notice_list_time {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.Notice_image {
  width: 100%;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
}

.Notice_box {
  width: 98%;
  border-left: 3px solid #c21d14;
  margin: 28px 0;
  height: 30px;
  padding-left: 8px;
  line-height: 30px;
}

.Notice_title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  float: left;
  clear: left;
}

.Notice_more {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  float: right;
  clear: right;
  cursor: pointer;
}

.Notice_more:hover {
  color: #c21d14;
}

.company {
  height: 510px;
  display: flex;
  justify-content: space-between;
}

.company_left,
.company_right {
  width: 48.34%;
  min-width: 483px;
}

.my-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #999;
  margin-top: 13px;
}

.Notice_list_item1 {
  display: flex;
  flex-direction: column;
}

.Notice_list_item1 .Notice_list_time1 {
  text-align: left;
}

.Notice_list_item1 .Notice_list_content1 {
  text-align: left;
  line-height: 24px;
  margin-top: 10px;
}
</style>
