<template>
  <div class="home">
    <swiperbig></swiperbig>
    <div class="width_1200">
      <swipersmall></swipersmall>
    </div>
    <openaccount></openaccount>
    <div class="width_1200">
      <noticecalendar></noticecalendar>
      <professionalwork></professionalwork>
    </div>
  </div>
</template>

<script>
import swiperbig from "./swiper/swiper_big.vue";
import swipersmall from "./swiper/swiper_small.vue";
import noticecalendar from "./notice_calendar";
import openaccount from "./open_account";
import professionalwork from "./professional_work";
// @ is an alias to /src

export default {
  name: "Index",
  components: {
    swiperbig,
    swipersmall,
    noticecalendar,
    openaccount,
    professionalwork,
  },
  watch: {
    $route: "handle_scrollTop",
  },
  created() {
    this.handle_scrollTop();
  },
  methods: {
    handle_scrollTop() {
      this.$("html,body").animate({ scrollTop: 0 }, 1000); //回到顶端
    }
  }
};
</script>
<style>
</style>
