<template>
  <div class="swiper1-box">
    <div class="swiper-container" id="swiper2">
      <div class="swiper-wrapper">
        <div class="redata-bg swiper-slide my_swiper-slide" v-for="(item, i) in last_major_indices" :key="i">
          <h3>{{ item.indexName }}</h3>
          <div :class="is_red(item.changeRate ? item.changeRate : '0.00%')">
            <h2>{{ item.newest.toFixed(2) }}</h2>
            <p>{{ item.changeRate }} % </p>
          </div>
        </div>
        <!-- <div class="swiper-slide my_swiper-slide">
          <img style="width: 200px;height: 85px;" src="../../../assets/img/n2.jpg" alt="">
        </div>
        <div class="swiper-slide my_swiper-slide">
          <img style="width: 200px;height: 85px;" src="../../../assets/img/n3.jpg" alt="">
        </div>
        <div class="swiper-slide my_swiper-slide">
          <img style="width: 200px;height: 85px;" src="../../../assets/img/n4.jpg" alt="">
        </div>
        <div class="swiper-slide my_swiper-slide">
          <img style="width: 200px;height: 85px;" src="../../../assets/img/n5.jpg" alt="">
        </div>
        <div class="swiper-slide my_swiper-slide">
          <img style="width: 200px;height: 85px;" src="../../../assets/img/n2.jpg" alt="">
        </div>
        <div class="swiper-slide my_swiper-slide">
          <img style="width: 200px;height: 85px;" src="../../../assets/img/n4.jpg" alt="">
        </div> -->
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination1"></div>

      <!-- 如果需要滚动条 -->
      <!--    <div class="swiper-scrollbar"></div>-->
    </div>
    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev my_prev"></div>
    <div class="swiper-button-next my_next"></div>
  </div>
</template>
<script>
import Swiper from "swiper";
import numeral from "numeral"
export default {
  data() {
    return {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 22, 33, 44, 55, 66],
      last_major_indices: [],
    };
  },
  filters: {
    formatNumber: function (num) {
      return numeral(num).format("+0,0.00");
    }
  },
  computed: {
    is_red() {
      return (data) => {
        return data.charAt(0) != '-' ? 'data-red' : 'data-green2'
      }
    }
  },
  created() {

  },
  mounted() {
    console.log()
    this.getlist();
    this.$nextTick(() => {
      new Swiper("#swiper2", {
        loop: true,
        // 如果需要分页器
        pagination: ".swiper-pagination1",
        // 如果需要前进后退按钮
        nextButton: ".my_next",
        prevButton: ".my_prev",
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        //如果需要自动切换海报
        slidesPerView: window.screen.width < 1200 ? 3 : 7,
        autoplay: 3000,
        // autoplay: 10000,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          swiper.startAutoplay();
          swiper.reLoop();
        },
      });
    });
  },
  methods: {
    getlist() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/last_major_indices`,
      }).then((response) => {
        this.last_major_indices = response.data.data;
      });
    },
  }
};
</script>
<style scoped>
.swiper1-box {
  height: 123px;
  position: relative;
  align-items: center;
  display: flex;
}

#swiper2 {
  height: 120px;
  width: 100%;
}

.my_prev {
  left: -6%;
  top: 50%;
  height: 42px;
  width: 42px;
  background-image: url("../../../assets/img/j4.png") !important;
  background-size: 42px;
}

.my_next {
  background-image: url("../../../assets/img/j3.png") !important;
  background-size: 42px;
  width: 42px;
  height: 42px;
  right: -6%;
  top: 50%;
}

.swiper-container {
  height: 617px;
  width: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 500px;
}

.my_swiper-slide {
  line-height: 68px;
}

.redata-bg {
  float: left;
  width: 141px;
  /* margin-right: 10px; */
  background: #fff;
  height: 80px;
  padding: 10px 0;
  margin-top: 10px;
  line-height: 26px;
  transition: all 0.3s;
}

.redata-bg h3 {
  font-size: 16px;
  text-align: center;
  color: #666;
  font-weight: 500;
}

.data-red {
  text-align: center;
  color: #ff1212;
}

.data-red h2 {
  font-size: 20px;
  font-weight: 600;
}

.data-green2 {
  text-align: center;
  color: #00b450;
}

.data-red p {
  font-size: 14px;
}
</style>
