<template>
  <div>
    <!-- <div class="Notice_box">
      <span class="Notice_title">业务公告</span>
      <span class="Notice_line"></span>
      <span class="Notice_more" @click="handle_more('业务公告', true)"
        >查看更多>></span
      >
    </div>
    <div class="Notice_box_1">
      <div class="Notice_img" @click="handle_business(noticeData, true)">
        <img
          class="my_img"
          style="width: 100%; height: 100%"
          src="@/assets/img/a5.jpg"
          alt=""
        />
        <div class="Notice_img_text">{{ noticeData.title }}</div>
      </div>
      <div class="Notice_list">
        <div
          class="Notice_list_item"
          :style="{
            'border-bottom':
              Notice_list.length == index - 0 + 1
                ? 'none'
                : '1px #E7E7E7 solid',
          }"
          v-for="(item, index) in Notice_list"
          :key="item.id"
        >
          <span class="Notice_list_yuan"></span>
          <span class="Notice_list_content" @click="handle_business(item, true)"
            >{{ item.title }}
          </span>
          <span class="Notice_list_time">{{ item.lastModified }}</span>
        </div>
      </div>
    </div> -->
    <div class="celerity_road">
      <el-row>
        <el-col :lg="3" :xs="24">
          <span class="celerity_road_title">快捷通道</span>
        </el-col>
        <el-col :lg="21" :xs="24" class="celerity_road_box">
          <el-col :lg="3" :xs="7" class="celerity_road_time">
            <div style="text-align: center">
              <img
                style="width: 27px; height: 29px; margin-top: 16px"
                src="@/assets/img/q3.png"
                alt=""
              />
            </div>
            <div class="celerity_road_time_text">账单查询</div>
            <div class="hover_celerity_road" @click="handle_bill_query">
              <div style="text-align: center; margin-top: 16px">
                <img
                  style="width: 27px; height: 29px"
                  src="@/assets/img/a4.png"
                  alt=""
                />
              </div>
              <div class="celerity_road_time_text" style="color: #fff">
                点击进入
              </div>
            </div>
          </el-col>
          <el-col :lg="3" :xs="7" class="celerity_road_time">
            <div style="text-align: center">
              <img
                style="width: 27px; height: 29px; margin-top: 16px"
                src="@/assets/img/z1.png"
                alt=""
              />
            </div>
            <div class="celerity_road_time_text">资金存取</div>
            <div class="hover_celerity_road" @click="handle_access_capital">
              <div style="text-align: center; margin-top: 16px">
                <img
                  style="width: 27px; height: 29px"
                  src="@/assets/img/a4.png"
                  alt=""
                />
              </div>
              <div class="celerity_road_time_text" style="color: #fff">
                点击进入
              </div>
            </div>
          </el-col>
          <el-col :lg="3" :xs="7" class="celerity_road_time">
            <div style="text-align: center">
              <img
                style="width: 27px; height: 29px; margin-top: 16px"
                src="@/assets/img/b1.png"
                alt=""
              />
            </div>
            <div class="celerity_road_time_text">表格下载</div>
            <div class="hover_celerity_road" @click="handle_form_download">
              <div style="text-align: center; margin-top: 16px">
                <img
                  style="width: 27px; height: 29px"
                  src="@/assets/img/a4.png"
                  alt=""
                />
              </div>
              <div class="celerity_road_time_text" style="color: #fff">
                点击进入
              </div>
            </div>
          </el-col>
          <el-col :lg="3" :xs="7" class="celerity_road_time">
            <div style="text-align: center">
              <img
                style="width: 27px; height: 29px; margin-top: 16px"
                src="@/assets/img/a1.png"
                alt=""
              />
            </div>
            <div class="celerity_road_time_text">常见问题</div>
            <div class="hover_celerity_road" @click="handle_common_problem">
              <div style="text-align: center; margin-top: 16px">
                <img
                  style="width: 27px; height: 29px"
                  src="@/assets/img/a4.png"
                  alt=""
                />
              </div>
              <div class="celerity_road_time_text" style="color: #fff">
                点击进入
              </div>
            </div>
          </el-col>
          <el-col :lg="3" :xs="7" class="celerity_road_time">
            <div style="text-align: center">
              <img
                style="width: 27px; height: 29px; margin-top: 16px"
                src="@/assets/img/a3.png"
                alt=""
              />
            </div>
            <div class="celerity_road_time_text">联系我们</div>
            <div class="hover_celerity_road" @click="handle_contact_us">
              <div style="text-align: center; margin-top: 16px">
                <img
                  style="width: 27px; height: 29px"
                  src="@/assets/img/a4.png"
                  alt=""
                />
              </div>
              <div class="celerity_road_time_text" style="color: #fff">
                点击进入
              </div>
            </div>
          </el-col>
          <el-col :lg="3" :xs="7" class="celerity_road_time">
            <div style="text-align: center">
              <img
                style="width: 27px; height: 29px; margin-top: 16px"
                src="@/assets/img/q4.png"
                alt=""
              />
            </div>
            <div class="celerity_road_time_text">诚聘精英</div>
            <div
              class="hover_celerity_road"
              @click="handle_recruitment_information"
            >
              <div style="text-align: center; margin-top: 16px">
                <img
                  style="width: 27px; height: 29px"
                  src="@/assets/img/a4.png"
                  alt=""
                />
              </div>
              <div class="celerity_road_time_text" style="color: #fff">
                点击进入
              </div>
            </div>
          </el-col>
        </el-col>
      </el-row>
    </div>
    <div class="Notice_box">
      <span class="Notice_title">公司动态</span>
      <span class="Notice_line"></span>
      <span class="Notice_more" @click="handle_more('公司动态', false)"
        >查看更多>></span
      >
    </div>
    <div class="Notice_box_1">
      <div class="Notice_img" @click="handle_business(transactionData, false)">
        <img
          class="my_img"
          style="width: 100%; height: 100%"
          src="@/assets/img/t1.jpg"
          alt=""
        />
        <div class="Notice_img_text">
          {{ transactionData.title }}
        </div>
      </div>
      <div class="Notice_list">
        <div
          class="Notice_list_item"
          :style="{
            'border-bottom':
              transaction_list.length == index - 0 + 1
                ? 'none'
                : '1px #E7E7E7 solid',
          }"
          v-for="(item, index) in transaction_list"
          :key="item.id"
        >
          <span class="Notice_list_yuan"></span>
          <span
            class="Notice_list_content"
            @click="handle_business(item, false)"
            >{{ item.title }}
          </span>
          <span class="Notice_list_time">{{ item.publishTime }}</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 32px">
      <el-row :gutter="27" class="home_bottom_column">
        <el-col :lg="6" :xs="10" class="home_bottom_column_item">
          <img
            class="img_"
            src="@/assets/img/z4.png"
            alt=""
            @click="handle_bottom_shortcut('党建工作')"
          />
        </el-col>
        <el-col :lg="6" :xs="10" class="home_bottom_column_item">
          <img
            class="img_"
            src="@/assets/img/z3.png"
            alt=""
            @click="handle_bottom_shortcut('反洗钱')"
          />
        </el-col>
        <el-col :lg="6" :xs="10" class="home_bottom_column_item">
          <img
            class="img_"
            src="@/assets/img/z2.png"
            alt=""
            @click="handle_complaint_advice()"
          />
        </el-col>
        <el-col :lg="6" :xs="10" class="home_bottom_column_item">
          <img
            class="img_"
            src="@/assets/img/q1.png"
            alt=""
            @click="handle_bottom_shortcut('信息公示')"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Notice_list: [],
      value: new Date(),
      transaction_list: [],
      transactionData: {},
      noticeData: {},
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    handle_complaint_advice() {
      this.$router.push({
        path: "/details",
        query: {
          id: '1469167555313698',
          my_id: '1470966597156897'
        },
      });
    },
    handle_contact_us() {
      this.$router.push({
        path: "/article_list",
        query: {
          column_id: '1469889244037154',
          id: '1469889661370401',
        },
      });
    },
    handle_common_problem() {
      this.$router.push({
        path: "/article_list",
        query: {
          // column_id: process.env.VUE_APP_common_problem_column,
          // id: process.env.VUE_APP_common_problem_nav,
          column_id: '1469893912297506',
          id: '1469895292223521',
        },
      });
    },
    handle_form_download() {
      this.$router.push({
        path: "/article_list",
        query: {
          column_id: process.env.VUE_APP_form_download_column,
          id: process.env.VUE_APP_form_download_nav,
        },
      });
    },
    handle_access_capital() {
      this.$router.push({
        path: "/article_list",
        query: {
          column_id: '1460630345220128',
          id: '1469167484010529',
        },
      });
    },
    handle_recruitment_information() {
      this.$router.push({
        path: "/article_list",
        query: {
          column_id: '1460490475667488',
          id: '1469170690555938',
        },
      });
    },
    handle_bill_query() {
      window.open(`https://investorservice.cfmmc.com`);
    },
    handle_bottom_shortcut(name) {
      if (name == '反洗钱') {
        window.open('http://edu.hyfutures.com/#/secondary_pages/investor_protection?type=3')
      } else
        this.$router.push({
          path: "/article_list",
          query: {
            name: name,
            column_id: name == '党建工作' ? '1458483067093024' : name == '信息公示' ? '1457937098735648' : '',
            id: name == '党建工作' ? "1469167081357346" : name == '信息公示' ? '1458500293099552' : '',
          },
        });
    },
    handle_more(name, type) {
      this.$router.push({
        path: "/article_list",
        query: {
          name: name,
          id: type
            ? process.env.VUE_APP_work_tips
            : process.env.VUE_APP_company_tips,
        },
      });
    },
    handle_business(data, type) {
      if (data.hasLink) {
        window.open(data.link)
      } else {
        this.$router.push({
          path: "/details",
          query: {
            name: data.title,
            my_id: data.id,
            //id: type ? process.env.VUE_APP_business_affiche : process.env.VUE_APP_deal_affiche,
            id: type
              ? process.env.VUE_APP_work_tips
              : process.env.VUE_APP_company_tips,
          },
        });
      }
    },
    getDataList() {
      // this.$axios({
      //   method: "get",
      //   headers: {
      //     'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
      //   },
      //   url: `/api/web/cms/list_by_column_name?columnName=业务公告&start=0&limit=8`,
      // }).then((response) => {
      //   let arr = [];
      //   response.data.rows.forEach((item) => {
      //     arr.push({
      //       ...item,
      //       lastModified: item.lastModified.split(" ")[0],
      //     });
      //   });
      //   // arr.find((item)=>item.cover);     查询
      //   this.Notice_list = arr;
      //   this.noticeData = {
      //     id: arr[0].id,
      //     title: arr[0].title,
      //   };
      // });
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/cms/list_by_column_name?columnName=公司动态&start=0&limit=8`,
      }).then((response) => {
        let arr = [];
        response.data.rows.forEach((item) => {
          arr.push({
            ...item,
            lastModified: item.lastModified.split(" ")[0],
          });
        });
        // arr.find((item)=>item.cover);     查询
        this.transaction_list = arr;
        this.transactionData = {
          id: arr[0].id,
          title: arr[0].title,
        };
      });
    },
  },
};
</script>
<style scoped>
.home_bottom_column {
  height: 130px;
}
.home_bottom_column_item {
  height: 130px;
  cursor: pointer;
}
.Notice_img_text {
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 10px 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
  text-align: left;
  box-sizing: border-box;
  padding: 0 30px;
}
.Notice_img_text:hover {
  text-decoration: underline;
}
.Notice_box_1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.img_ {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.img_:hover {
  box-shadow: 0px 0px 10px #000;
}
.a_ {
  height: 108px;
}
.hover_celerity_road {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.85;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateX(270deg);
  transition: all 0.3s ease-in-out;
}
.celerity_road_time:hover .hover_celerity_road {
  transform: rotateX(360deg);
}
.celerity_road_time_text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  text-align: center;
}
.celerity_road_right {
  display: inline-flex;
  justify-content: space-around;
  align-content: center;
  flex-direction: row;
}
.celerity_road_time0 {
  margin-right: 20px;
}
.celerity_road_time {
  position: relative;
  height: 85px;
  background: #f3f3f3;
  border-radius: 8px;
  margin-right: 40px;
  cursor: pointer;
}
.celerity_road_title {
  font-size: 30px;
  line-height: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  width: 70px;
  display: inline-block;
  letter-spacing: 2px;
  margin: 11px 0 9px 18px;
  padding-right: 37px;
  border-right: 1px #ececec solid;
}
.celerity_road {
  height: 85px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 10px;
  margin-top: 33px;
  padding: 20px 0 20px 20px;
}
.Notice_img {
  width: 48%;
  min-width: 483px;
  height: 390px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}
.Notice_list {
  margin-left: 4%;
  width: 48%;
  min-width: 483px;
  height: 390px;
  display: inline-block;
  margin-top: -15px;
}
.Notice_list_item {
  height: 51px;
  line-height: 51px;
  padding-left: 17px;
  position: relative;
}
.Notice_list_yuan {
  position: absolute;
  top: 20px;
  left: 1px;
  width: 5px;
  height: 5px;
  background: #e0e0e0;
  border-radius: 2px;
}
.Notice_list_content {
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.Notice_list_content:hover {
  color: #c21d14;
}
.Notice_list_time {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.Notice_line {
  border-bottom: 1px dashed #ccc;
  width: 79%;
  height: 16px;
  float: left;
  margin-left: 18px;
}
.Notice_box {
  width: 99%;
  border-left: 3px solid #c21d14;
  margin: 41px 0 31px 0;
  height: 30px;
  padding-left: 8px;
  line-height: 30px;
}
.Notice_title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  float: left;
  clear: left;
}
.Notice_more {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  float: right;
  clear: right;
}
.Notice_more:hover {
  color: #c21d14;
}
</style>
